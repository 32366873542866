body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    line-height: 22px;
}

p {
    margin: 0;
}

h1,
h2,
h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

h1 {
    font-size: 24px;
    margin-bottom: 30px;
}

h2 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 10px;
}

h3 {
    margin: 0;
    margin-bottom: 6px;
}

a,
a:visited {
    color: black;
}

a:hover {
    text-decoration: none;
}

* {
    box-sizing: border-box;
}
